import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitterSquare, faSkype, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import me from './img/IMG_8814.resize.jpg';

const iconSocial = "text-gray-400 hover:text-indigo-500";
const link = "text-gray-400 hover:text-indigo-500";
const age = new Date().getFullYear()-1981;

function App() {
    return (
        <div className="border shadow hover:shadow-md mx-auto bg-white rounded w-72 md:w-96 flex flex-col space-y-2">

            <img src={me} className="rounded-tl rounded-tr" alt="Zugravu Eugen Marius" />

            <div className="pl-2 pr-2 text-lg text-center text-indigo-500">
                Full Stack developer. System administrator.
            </div>

            <div className="pl-2 pr-2 text-center italic text-indigo-500">
                Developing with pleasure since 2004.
            </div>

            <div className="pl-2 pr-2">
                Developer: <a href="https://www.php.net/" className={link} target="_blank">PHP</a>, HTML, CSS, JavaScript (ES...), <a href="https://nodejs.org/en/" className={link} target="_blank">NodeJs</a>, <a href="https://reactjs.org/" className={link} target="_blank">React</a>, <a href="https://www.sencha.com/products/extjs/" className={link} target="_blank">ExtJs</a> and others.
            </div>

            <div className="pl-2 pr-2">
                Sysadmin: <a href="https://ubuntu.com/" className={link} target="_blank">Ubuntu Server LTS</a>, Dedicated Servers, Remote management (IPMI, KVM over IP, etc.), <a href="https://www.nginx.com/" className={link} target="_blank">Nginx</a>, <a href="https://traefik.io/" className={link} target="_blank">Traefik (experimental)</a>, <a href="https://apache.org/" className={link} target="_blank">Apache</a>, <a href="https://redis.io/" className={link} target="_blank">Redis</a>, <a href="https://www.mysql.com/" className={link} target="_blank">MySQL</a>, <a href="https://openvpn.net/" className={link} target="_blank">OpenVPN</a>, <a href="https://www.pfsense.org/" className={link} target="_blank">pfsense</a> and others.
            </div>

            <div className="pl-2 pr-2">
                Cloud: <a href="https://aws.amazon.com/" className={link} target="_blank">Amazon Web Services</a>, <a href="https://cloud.google.com/" className={link} target="_blank">Google Cloud Platform</a>, VPS, VDS, Shared Hosting
            </div>

            <div className="pl-2 pr-2">
                Personal: Born 17.03.1981 ({age} years old), married, 1 wife, 2 kids, 2 cars, no dog (yet). Living in <a href="https://www.google.com/maps/place/Ia%C8%99i/@47.1562327,27.516931,12z" className={link} target="_blank">Iasi, Romania</a>
            </div>

            <div>
                <hr />
            </div>

            <div class="pl-2 pr-2 text-center flex flex-row space-x-2 mx-auto pb-2">
                <a href="https://www.linkedin.com/in/zugravu-eugen-marius-6970ba94/" target="_blank" className={iconSocial}><FontAwesomeIcon icon={faLinkedin} size="lg" /></a>
                <a href="https://twitter.com/zmarius81" target="_blank" className={iconSocial}><FontAwesomeIcon icon={faTwitterSquare} size="lg" /></a>
                <a href="skype:zmarius81?chat" target="_blank" className={iconSocial}><FontAwesomeIcon icon={faSkype} size="lg" /></a>
                <a href="https://www.facebook.com/zugravu.eugenmarius.1/" target="_blank" className={iconSocial}><FontAwesomeIcon icon={faFacebookSquare} size="lg" /></a>
            </div>
        </div>
    );
}

export default App;
